import {
  Borders,
  OddSpacingsKeys,
  Radii,
  Spacings,
  SpacingsKeys
} from './types';

const radii: Radii = [0, 4, 8, 12];
const borders: Borders = [0, 1, 2];
const baseSize: number = 8;
const baseSpace: number = 4;

// Typescript does not have a way to specify range of array
// or infer range from a constant array. So we typecast into unknown
// then into Spacings
const sizes: Spacings = SpacingsKeys.map<number>(
  space => baseSize * space
) as unknown as Spacings;

const minWidths = SpacingsKeys.map<number>(
  space => baseSize * space
) as unknown as Spacings;

const maxWidths = SpacingsKeys.map<number>(
  space => baseSize * space
) as unknown as Spacings;

const minHeights = SpacingsKeys.map<number>(
  space => baseSize * space
) as unknown as Spacings;

const maxHeights = SpacingsKeys.map<number>(
  space => baseSize * space
) as unknown as Spacings;

const heights = SpacingsKeys.map<number>(
  space => baseSize * space
) as unknown as Spacings;

const space = SpacingsKeys.map<number>(
  space => baseSpace * space
) as unknown as Spacings;

const oddSpace = OddSpacingsKeys.map<number>(space => baseSpace * space);

const breakpoints = {
  sm: '1280px',
  md: '1440px',
  lg: '1920px'
};

export default {
  space,
  sizes,
  minWidths,
  maxWidths,
  minHeights,
  maxHeights,
  heights,
  radii,
  borders,
  baseSize,
  baseSpace,
  oddSpace,
  breakpoints
};
