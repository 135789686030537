import React from 'react';
import { Img, FlexBox } from 'tuxedo-v2';

// logos
import SynapticLogo from 'theme/assets/images/synaptic-logo-typeface-4x.png';
import SynapticLogoMark from 'theme/assets/images/logomark.png';
import SynapticLogoMarkV2 from 'theme/assets/images/logomark-v2.png';
import SynapticTextLogo from 'theme/assets/images/synaptic-text.png';

import SanabilLogo from 'theme/assets/images/sanabil-logo-typeface-3x.png';

// images
import SideImage from 'theme/assets/images/auth-module/side-image.png';
import SanabilSideImage from 'theme/assets/images/auth-module/sanabil-side-image.png';

import { ImageConfig } from '../types';
import { getAppName } from 'shared/services/app';
import { APP_NAMES } from 'shared/constants';

const synapticConfig: ImageConfig = {
  logo: <Img src={SynapticLogo} width={'166.5px'} _height={'36px'} />,
  logoMark: <Img src={SynapticLogoMark} />,
  logoMarkV2: <Img src={SynapticLogoMarkV2} width='40px' _height='40px' />,
  textLogo: (
    <FlexBox alignItems='center'>
      <Img src={SynapticLogoMark} />
      <Img src={SynapticTextLogo} />
    </FlexBox>
  ),
  sideImage: (
    <Img
      src={SideImage}
      width='100%'
      style={{ objectFit: 'cover', objectPosition: 'top' }}
    />
  ),
  favicon: (
    <link rel='icon' type='image/png' href={SynapticLogoMark} sizes='16x16' />
  )
};

const sanabilConfig: ImageConfig = {
  logo: <Img src={SanabilLogo} width={'166.5px'} _height={'36px'} />,
  logoMark: null,
  logoMarkV2: null,
  textLogo: <Img src={SanabilLogo} />,
  sideImage: (
    <Img
      src={SanabilSideImage}
      width='100%'
      style={{ objectFit: 'cover', objectPosition: 'top' }}
    />
  ),
  favicon: (
    <link rel='icon' type='image/png' href={SynapticLogoMark} sizes='16x16' />
  )
};

export const imageConfig =
  getAppName() === APP_NAMES.SANABIL ? sanabilConfig : synapticConfig;
