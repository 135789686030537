import APP_ENV from 'shared/network/build-env';
import { getServer } from 'shared/services/server';
import { PRODUCTION_URL } from 'shared/network/config';
import { APP_NAMES } from '../../../constants';
import { getAppName } from '../../../services/app';

const clientIdConfig = {
  [APP_NAMES.SYNAPTIC]: {
    stage: '733315021460beefdcb40de69409ab57a33af0471b6dfc74ebc2213b630152ea',
    production:
      '1148d98c6ae90bbcca0c8baf9f0e556b6e82075b4f7456240ac1c6308d774748'
  },
  [APP_NAMES.SANABIL]: {
    stage: '57ee9404bfbd8600c36881180328131ca946670ad3dc342c3fef2e650297fddc',
    production:
      'f88453391a99c4b2d20419b3db9a7eba8fbaf78603ef724ac678cfc1ac27f636'
  }
};

const appClientIds =
  clientIdConfig[getAppName() as keyof typeof clientIdConfig];

let fpEndpoint = 'https://fp.stage.synaptic.services';
let clientId = appClientIds.stage;

const SERVER = getServer();

if ((APP_ENV === 'production' && !SERVER) || SERVER === PRODUCTION_URL) {
  fpEndpoint = 'https://fp.synaptic.com';
  clientId = appClientIds.production;
}

export const CLIENT_ID = clientId;
export const FPJS_ENDPOINT = fpEndpoint;
export const FIREBASE_WEB_CLIENT_ID =
  '896930683139-lrkfn816solau4j3m82ufjngeilt49ul.apps.googleusercontent.com';
