import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import GlobalStyleV2 from 'tuxedo-v2/globals';
import GlobalFontStyleV2 from 'tuxedo-v2/globals/font-icon';
import ThemeProviderV2 from 'theme-v2/provider/index';
import { initializeExcelAddInLogin } from 'shared/modules/auth-module/utils/excel-addin';
import queryClient from 'shared/network/query-client';
import Routes from './routes';
import {
  FPJS_API_KEY,
  FPJS_ENDPOINT
} from 'shared/modules/auth-module/constants';

import Helmet from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import './index.css';

import { imageConfig } from './config';
import { THEME_NAME } from './constants';

const AUTH_CODE_TIMEOUT = 10 * 60 * 1000; // 10 minutes in milliseconds

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  setTimeout(() => {
    window.location.reload();
  }, AUTH_CODE_TIMEOUT);

  const startApp = isExcelAddInInstance =>
    root.render(
      <FpjsProvider
        loadOptions={{
          disableTls: true,
          apiKey: FPJS_API_KEY,
          endpoint: FPJS_ENDPOINT,
          scriptUrlPattern: `${FPJS_ENDPOINT}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`
        }}
      >
        <ThemeProviderV2 defaultMode='light' themeName={THEME_NAME}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Helmet>{imageConfig.favicon}</Helmet>
              <Routes isExcelAddInInstance={isExcelAddInInstance} />
            </BrowserRouter>
          </QueryClientProvider>
          <GlobalStyleV2 />
          <GlobalFontStyleV2 />
        </ThemeProviderV2>
      </FpjsProvider>
    );

  initializeExcelAddInLogin(startApp);
}
