import { APP_NAMES } from 'shared/constants';
import { ThemeMap } from 'theme-v2/provider/util';

export const getAppName = (): APP_NAMES => {
  return window.sessionStorage.sanabil_org ||
    window.location.hostname.includes('sanabil')
    ? APP_NAMES.SANABIL
    : APP_NAMES.SYNAPTIC;
};

export const getThemeName = (): keyof typeof ThemeMap | null => {
  return getAppName() === APP_NAMES.SANABIL ? 'sanabil' : null;
};
