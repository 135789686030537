export const CODE_VERIFIER_LENGTH = 48;
export const FPJS_API_KEY = '89GH82zODFbWS0ekGNno';
import APP_ENV from 'shared/network/build-env';

const assetsUrl =
  APP_ENV === 'production'
    ? 'https://assets.synaptic.com/'
    : 'https://assets.stage.synaptic.services/';

export const IDP_LIST = [
  {
    name: 'Okta',
    provider: 'okta',
    slug: 'okta',
    logo: `${assetsUrl}assets/Okta_logo+1.png`
  },
  {
    name: 'Microsoft',
    provider: 'azure',
    slug: 'azure',
    logo: `${assetsUrl}assets/microsoft-auth-logo.png`
  },
  {
    name: 'Google',
    provider: 'google',
    slug: 'google',
    logo: `${assetsUrl}assets/Google+(1).png`
  },
  {
    name: 'SAML SSO',
    provider: 'saml',
    slug: 'saml_sso',
    logo: `${assetsUrl}assets/saml.png`
  }
];

export {
  FIREBASE_WEB_CLIENT_ID,
  CLIENT_ID,
  FPJS_ENDPOINT
} from 'shared/modules/auth-module/constants/platform-config';
