import colors, { colorPalette } from './colors';
import typography from '../../typography';
import space from '../../space';
import text from '../../text';
import { type ThemeMode } from '../../provider';

export default {
  ...typography,
  ...space,
  colors,
  colorPalette,
  text,
  themeName: 'sanabil',
  themeMode: 'light' as ThemeMode
};
